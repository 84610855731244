import React from 'react';
import coins from '../assets/coins.png';
import groupWomen from '../assets/group_women.png';
import handPhone from '../assets/hand_phone.png';
import customer from '../assets/customer.png';
import logo from '../assets/NeigbhorCoinLogo.png';
import GoogleSignIn from '../components/GoogleSignInButton';
import WelcomeMessage from '../components/WelcomeMessage';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import useAuthState from '../hooks/useAuthState';
import heroBackground from '../assets/hero-background.webp'; // Add your hero image

function Home() {
  const isAuthenticated = useAuthState(auth);
  const user = auth.currentUser;
  const userEmail = user ? user.email : ''; 
  const userRole = user ? user.role : 'attendee'; 

  const roleDisplayNames = {
    businessOwner: "Business Venue Owner",
    eventPlanner: "Event Planner",
    attendee: "Attendee"
  };

  const getDashboardLink = () => {
    if (userRole === 'businessOwner') return '/business-dashboard';
    if (userRole === 'eventPlanner') return '/event-admin';
    return '/dashboard';
  };

  return (
    <div className="min-h-screen bg-neutralLight text-neutral flex flex-col items-center font-sans">
      <section 
        className="w-full bg-cover bg-center py-20 px-4"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${heroBackground})`,
          minHeight: '70vh'
        }}
      >
        <div className="max-w-4xl mx-auto text-center text-white">
          <img src={logo} alt="NeighborCoin Logo" className="w-48 mx-auto mb-8" />
          <h1 className="font-display text-4xl md:text-6xl font-bold mb-6">
            Retain Loyal Customers
          </h1>
          <p className="text-xl md:text-2xl mb-8">
            Turn first-time visitors into repeat customers with NeighborhoodCoins. Join us to discover local events, earn rewards, and support businesses in your community.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            {!isAuthenticated && (
              <GoogleSignIn className="bg-accent hover:bg-highlight text-white font-bold py-3 px-8 rounded-full text-lg transition-colors" />
            )}
          </div>
        </div>
      </section>

      <main className="flex-grow w-full px-6">
        {isAuthenticated && (
          <WelcomeMessage
            auth={auth}
            userEmail={userEmail}
            userRole={userRole}
            roleDisplayNames={roleDisplayNames}
            getDashboardLink={getDashboardLink}
          />
        )}

        {/* How It Works Section */}
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-center mb-4">How It Works</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="flex flex-col items-center text-center p-4 border rounded-lg">
              <h3 className="text-lg font-semibold mb-2">1. Sign Up</h3>
              <p className="text-sm mb-4">
                Choose the perfect plan for your business—starting at just $29/month. Get listed in our exclusive local directory.
              </p>
              <Link to="/signup" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
                Get Started Today!
              </Link>
            </div>
            <div className="flex flex-col items-center text-center p-4 border rounded-lg">
              <h3 className="text-lg font-semibold mb-2">2. Create Rewards</h3>
              <p className="text-sm mb-4">
                Design rewards your customers will love, like “Buy 5, Get 1 Free” or “Earn Points for Every Visit.”
              </p>
            </div>
            <div className="flex flex-col items-center text-center p-4 border rounded-lg">
              <h3 className="text-lg font-semibold mb-2">3. Engage Customers</h3>
              <p className="text-sm mb-4">
                Launch your loyalty program and let your customers start earning rewards. Track visits and redemptions.
              </p>
            </div>
            <div className="flex flex-col items-center text-center p-4 border rounded-lg">
              <h3 className="text-lg font-semibold mb-2">4. Grow Business</h3>
              <p className="text-sm mb-4">
                Receive ongoing support and insights. Upgrade anytime for premium features like exclusive promotions.
              </p>
              <Link to="/contact" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
                Free Consultation!
              </Link>
            </div>
          </div>
        </section>

        {/* Business and Event Planner CTA Section */}
        <section className="mt-8 bg-secondary text-white py-6 px-4 rounded-lg text-center">
          <h2 className="text-2xl font-bold mb-4">Are You a Local Business or Event Planner?</h2>
          <p className="text-lg">Get 2 months free when you list your business or upcoming events today. Connect with engaged, local customers.</p>
          <Link to="/signup" className="bg-white text-primary font-bold py-2 px-4 mt-4 inline-block rounded hover:bg-gray-200">
            Sign Up Today
          </Link>
        </section>

        {/* Services Section */}
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-center mb-4">Our Services</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="p-4 border rounded-lg text-center">
              <h3 className="text-xl font-semibold mb-2">Basic Package</h3>
              <p className="text-sm mb-4">Ideal for small businesses new to loyalty programs who want a simple, low-cost solution.</p>
              <p className="text-lg font-bold mb-4">$29/month</p>
              <ul className="list-disc list-inside text-left mb-4">
                <li>Feature in Our Directory</li>
                <li>Basic Analytics</li>
                <li>Email Support</li>
              </ul>
              <Link to="/signup" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
                Get Started
              </Link>
            </div>
            <div className="p-4 border rounded-lg text-center">
              <h3 className="text-xl font-semibold mb-2">Standard Package</h3>
              <p className="text-sm mb-4">Perfect for businesses ready to grow their customer base and optimize rewards.</p>
              <p className="text-lg font-bold mb-4">$59/month</p>
              <ul className="list-disc list-inside text-left mb-4">
                <li>All Basic Features + Advanced Analytics</li>
                <li>Custom Rewards</li>
                <li>Priority Email Support</li>
              </ul>
              <Link to="/signup" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
                Get Started
              </Link>
            </div>
            <div className="p-4 border rounded-lg text-center">
              <h3 className="text-xl font-semibold mb-2">Premium Package</h3>
              <p className="text-sm mb-4">Best for established businesses seeking hands-on support and strategic marketing.</p>
              <p className="text-lg font-bold mb-4">$99/month</p>
              <ul className="list-disc list-inside text-left mb-4">
                <li>Dedicated Account Manager</li>
                <li>24/7 Support</li>
                <li>Exclusive Promotions</li>
              </ul>
              <Link to="/signup" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
                Get Started
              </Link>
            </div>
          </div>
          
        </section>

        {/* Feedback Link */}
        <div className="mt-8 text-center">
          <p>We’d love to hear from you! {' '}
            <Link to="https://forms.gle/yJbTcohiQXQRpsRU7" target='_blank' className="text-accent font-bold hover:text-highlight">
              Give Feedback
            </Link>
          </p>
        </div>
      </main>
    </div>
  );
}

export default Home;