import React from 'react';
import { Link } from 'react-router-dom';  // For navigation to relevant pages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faStoreAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Events from '../pages/Events'; 

const HowItWorks = () => {
  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center text-primary mb-6">How NeighborhoodCoins Works</h2>
      
      {/* Introduction Section */}
      <div className="mb-8 bg-turquoise p-4 rounded-lg">
        <p className="text-neutral mb-4">
        At NeighborhoodCoins, we help local businesses like yours turn one-time customers into loyal regulars. We handle the setup, management, and success tracking for your loyalty rewards program, so you can focus on running your business.        </p>
      </div>
      
      {/* How It Works Section */}
      <section className="mt-8 mb-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="flex flex-col items-center text-center p-4 border rounded-lg">
            <h3 className="text-lg font-semibold mb-2">1. Sign Up</h3>
            <p className="text-sm mb-4">
              Choose the perfect plan for your business—starting at just $29/month. Get listed in our exclusive local directory.
            </p>
            <Link to="/signup" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
              Get Started Today!
            </Link>
          </div>
          <div className="flex flex-col items-center text-center p-4 border rounded-lg">
            <h3 className="text-lg font-semibold mb-2">2. Create Rewards</h3>
            <p className="text-sm mb-4">
              Design rewards your customers will love, like “Buy 1, Get 1 Free” or “Earn Coins for Every Visit.”
            </p>
          </div>
          <div className="flex flex-col items-center text-center p-4 border rounded-lg">
            <h3 className="text-lg font-semibold mb-2">3. Engage Customers</h3>
            <p className="text-sm mb-4">
              Launch your loyalty program and let your customers start earning rewards. Track visits and redemptions.
            </p>
          </div>
          <div className="flex flex-col items-center text-center p-4 border rounded-lg">
            <h3 className="text-lg font-semibold mb-2">4. Grow Business</h3>
            <p className="text-sm mb-4">
              Receive ongoing support and insights. Upgrade anytime for premium features like exclusive promotions.
            </p>
            <Link to="/contact" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
              Free Consultation!
            </Link>
          </div>
        </div>
      </section>

      {/* Why NeighborhoodCoins Section */}
      <section className="mt-8 mb-8">
        <h2 className="text-2xl font-bold text-center mb-4">Why NeighborhoodCoins?</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="flex flex-col items-center text-center p-4 border rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Hassle-Free Loyalty Programs</h3>
            <p className="text-sm mb-4">
              We take care of everything, from setup to reporting.
            </p>
          </div>
          <div className="flex flex-col items-center text-center p-4 border rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Affordable and Scalable</h3>
            <p className="text-sm mb-4">
              Plans start at just $29/month, with features designed to grow alongside your business.
            </p>
          </div>
          <div className="flex flex-col items-center text-center p-4 border rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Community Focused</h3>
            <p className="text-sm mb-4">
              Connect with customers who love supporting local businesses like yours.
            </p>
          </div>
        </div>
        <div className="text-center mt-6">
          <Link to="/signup" className="bg-primary text-white py-3 px-6 rounded-lg hover:bg-highlight text-lg font-bold">
            Choose Your Plan and Start Today!
          </Link>
        </div>
      </section>

      {/* Events Section */}
      <Events />
    </div>
  );
};

export default HowItWorks;